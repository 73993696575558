import { Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { Badge } from "./Badge.tsx";
import { BadgeInformationWrapper, BadgesList } from "./styled.ts";
import { ScrollableHeightContainer } from "../DynamicHeightContainer";

export function BadgeInformation() {
  const { translate } = useLocales();
  return (
    <BadgeInformationWrapper>
      <Typography fontSize="14px" fontWeight="600" color="text.primary">
        {translate("assessment.recentBadges.title")}
      </Typography>
      <BadgesList>
        {/*TODO: ADD API*/}
        <ScrollableHeightContainer count={3}>
          {(elementRefs) => (
            <>
              <Badge kind="GUARDIAN" ref={(el) => (elementRefs.current[0] = el)} />
              <Badge kind="GUARDIAN" ref={(el) => (elementRefs.current[1] = el)} />
              <Badge kind="GUARDIAN" ref={(el) => (elementRefs.current[2] = el)} />
              <Badge kind="GUARDIAN" ref={(el) => (elementRefs.current[3] = el)} />
              <Badge kind="GUARDIAN" ref={(el) => (elementRefs.current[4] = el)} />
              <Badge kind="GUARDIAN" ref={(el) => (elementRefs.current[5] = el)} />
            </>
          )}
        </ScrollableHeightContainer>
      </BadgesList>
    </BadgeInformationWrapper>
  );
}
