import { Box, SxProps } from "@mui/material";
import { EmployeeLevelData, EmployeeLevelKind } from "./consts.tsx";

interface LevelLogoProps {
  levelKind: EmployeeLevelKind;
  sx?: SxProps;
}

export function LevelLogo({ levelKind = "NOVICE", sx }: LevelLogoProps) {
  return (
    <Box sx={{ height: "100%", width: "100%", ...sx }}>{EmployeeLevelData[levelKind].icon}</Box>
  );
}
