import { EarnedBadge } from "./EarnedBadge.tsx";
import { Box, Button } from "@mui/material";
import { useLocales } from "../../hooks";
import { useHistory } from "react-router-dom";

function BadgeResultScreen() {
  const { translate } = useLocales();
  const history = useHistory();
  const handleClose = () => {
    history.goBack();
  };

  const handleViewDashboard = () => {
    // TODO: IMPLEMENT BASED ON BE
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <EarnedBadge />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Button variant="contained" fullWidth onClick={handleViewDashboard}>
          {translate("assessment.badges.viewDashboard")}
        </Button>
        <Button variant="outlined" fullWidth onClick={handleClose}>
          {translate("common.close")}
        </Button>
      </Box>
    </Box>
  );
}

export default BadgeResultScreen;
