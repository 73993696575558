import { Alert, styled, Typography } from "@mui/material";
import { Trans } from "react-i18next";

const TypographyIcon = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  line-height: 24.4px;
`;

export interface ForwardingStatusProps {
  status: "success" | "error";
}
export function ForwardingStatus({ status }: ForwardingStatusProps) {
  const components = {
    1: <strong />,
  };
  return (
    <Alert
      severity={status}
      iconMapping={{
        success: <TypographyIcon>✅</TypographyIcon>,
        error: <TypographyIcon>⛔</TypographyIcon>,
      }}
    >
      <Trans i18nKey={`forwarding.${status}` as const} components={components} />
    </Alert>
  );
}
