import ReporterBadge from "../../assets/badges/consistentReporter.svg?react";
import GuardianBadge from "../../assets/badges/phishingGuardian.svg?react";
import ResponderBadge from "../../assets/badges/rapidResponder.svg?react";
import MonthlyTopBadge from "../../assets/badges/topScorer.svg?react";
import EarlyAdopterBadge from "../../assets/badges/earlyAdopter.svg?react";
import SpeedsterBadge from "../../assets/badges/speedster.svg?react";

export const EMPLOYEE_BADGES = {
  REPORTER: {
    kind: "REPORTER",
    icon: <ReporterBadge style={{ width: "100%", height: "100%" }} />,
    title: "assessment.badges.consistentReporter.title",
    subtitle: "assessment.badges.consistentReporter.subtitle",
    modal: {
      description: "assessment.badges.consistentReporter.modal.description",
    },
  },
  GUARDIAN: {
    kind: "GUARDIAN",
    icon: <GuardianBadge style={{ width: "100%", height: "100%" }} />,
    title: "assessment.badges.phishingGuardian.title",
    subtitle: "assessment.badges.phishingGuardian.subtitle",
    modal: {
      description: "assessment.badges.phishingGuardian.modal.description",
    },
  },
  RESPONDER: {
    kind: "RESPONDER",
    icon: <ResponderBadge style={{ width: "100%", height: "100%" }} />,
    title: "assessment.badges.rapidResponder.title",
    subtitle: "assessment.badges.rapidResponder.subtitle",
    modal: {
      description: "assessment.badges.rapidResponder.modal.description",
    },
  },
  MONTHLY_TOP: {
    kind: "MONTHLY_TOP",
    icon: <MonthlyTopBadge style={{ width: "100%", height: "100%" }} />,
    title: "assessment.badges.monthlyTopScorer.title",
    subtitle: "assessment.badges.monthlyTopScorer.subtitle",
    modal: {
      description: "assessment.badges.monthlyTopScorer.modal.description",
    },
  },
  ADOPTER: {
    kind: "ADOPTER",
    icon: <EarlyAdopterBadge style={{ width: "100%", height: "100%" }} />,
    title: "assessment.badges.earlyAdopter.title",
    subtitle: "assessment.badges.earlyAdopter.subtitle",
    modal: {
      description: "assessment.badges.earlyAdopter.modal.description",
    },
  },
  SPEEDSTER: {
    kind: "SPEEDSTER",
    icon: <SpeedsterBadge style={{ width: "100%", height: "100%" }} />,
    title: "assessment.badges.speedster.title",
    subtitle: "assessment.badges.speedster.subtitle",
    modal: {
      description: "assessment.badges.speedster.modal.description",
    },
  },
  // TODO: add proper NEW_LEVEL when API implemented
  NEW_LEVEL: {
    kind: "NEW_LEVEL",
    icon: <MonthlyTopBadge style={{ width: "100%", height: "100%" }} />,
    title: "assessment.badges.monthlyTopScorer.title",
    subtitle: "assessment.badges.monthlyTopScorer.subtitle",
    modal: {
      description: "assessment.badges.monthlyTopScorer.modal.description",
    },
  },
} as const;

export const getBadgeData = (badgeKind: keyof typeof EMPLOYEE_BADGES | undefined) => {
  if (!badgeKind) return null;
  return badgeKind in EMPLOYEE_BADGES
    ? EMPLOYEE_BADGES[badgeKind as keyof typeof EMPLOYEE_BADGES]
    : null;
};
