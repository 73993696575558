import { useMutation } from "react-query";
import { apiHandlers, AssessmentRequestData, getMessageId } from "../api";
import { getAdaptedCacheLocale } from "../locales";
import { useLoginContext } from "../api/loginContext";

export function useReportMutation() {
  const { accessToken } = useLoginContext();
  return useMutation((data: Pick<AssessmentRequestData, "user_message">) => {
    const messageId = getMessageId();
    console.log("mail id for report is", messageId);

    return apiHandlers.assessment.check(
      {
        ...data,
        message_id: messageId,
        language: getAdaptedCacheLocale(),
        token: accessToken ?? undefined,
      },
      { headers: { Authorization: `${accessToken}` } },
    );
  });
}
