import { createContext, useContext } from "react";

let loginDialog: Office.Dialog;
const dialogLoginUrl: string =
  location.protocol +
  "//" +
  location.hostname +
  (location.port ? ":" + location.port : "") +
  "/login/login.html";

export const tokenStorageKey = "access_token";

export const signInO365 = (
  setToken: (x: string, access: string) => void,
  setUserName: (x: string) => void,
  displayError: (x: string) => void,
) => {
  Office.context.ui.displayDialogAsync(dialogLoginUrl, { height: 60, width: 40 }, (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      displayError(`${result.error.code} ${result.error.message}`);
    } else {
      loginDialog = result.value;
      loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
      loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
    }
  });

  const processLoginMessage = (
    arg:
      | {
          message: string;
          origin: string | undefined;
        }
      | { error: number },
  ) => {
    console.log("processLoginMessage arg", arg);
    if ("error" in arg) {
      throw new Error("Error in processLoginMessage.");
    }
    // Confirm origin is correct.
    if (arg.origin !== window.location.origin) {
      throw new Error("Incorrect origin passed to processLoginMessage.");
    }

    const messageFromDialog = JSON.parse(arg.message);
    if (messageFromDialog.status === "success") {
      // We now have a valid access token.
      loginDialog.close();
      setToken(messageFromDialog.token, "MSN " + messageFromDialog.accessToken);
      setUserName(messageFromDialog.userName);
    } else {
      // Something went wrong with authentication or the authorization of the web application.
      loginDialog.close();
      displayError(messageFromDialog.result);
    }
  };

  const processLoginDialogEvent = (
    arg:
      | {
          message: string;
          origin: string | undefined;
        }
      | { error: number },
  ) => {
    processDialogEvent(arg, displayError);
  };
};

const processDialogEvent = (
  arg:
    | {
        message: string;
        origin: string | undefined;
      }
    | { error: number },
  displayError: (x: string) => void,
) => {
  if ("error" in arg) {
    switch (arg.error) {
      case 12002:
        displayError(
          "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.",
        );
        break;
      case 12003:
        displayError(
          "The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.",
        );
        break;
      case 12006:
        // 12006 means that the user closed the dialog instead of waiting for it to close.
        // It is not known if the user completed the login or logout, so assume the user is
        // logged out and revert to the app's starting state. It does no harm for a user to
        // press the login button again even if the user is logged in.
        break;
      default:
        displayError("Unknown error in dialog box.");
        break;
    }
  }
};

const MAX_RETRIES = 3;
let retryCount = 0;

export function getOfficeAccessTokenLegacy(
  setToken: (x: string, access?: string) => void,
  setFailed: (x: boolean) => void,
): void {
  Office.auth
    .getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: true,
    })
    .then((token) => {
      retryCount = 0;
      setToken(token, "OBO " + token);
    })
    .catch((error) => {
      if (retryCount < MAX_RETRIES) {
        retryCount++;
        console.log(`Token acquisition failed. Retrying... Attempt: ${retryCount}`);
        getOfficeAccessTokenLegacy(setToken, setFailed);
      } else {
        retryCount = 0;
        console.log(
          `Token acquisition failed after maximum retries. Reason: ${JSON.stringify(error)}`,
        );
        setFailed(true);
      }
    });
}

export interface LoginContextType {
  accessToken?: string | null;
  idToken?: string;
  login?: () => void;
  markIdentityAPIFailed?: () => void;
}

export const LoginContextInternal = createContext<LoginContextType>({
  accessToken: undefined,
});

export const useLoginContext = () => useContext<LoginContextType>(LoginContextInternal);

export function requestStorageAccess(
  setNeedStorageAccess: (value: boolean, blocked: boolean) => void,
) {
  document
    .requestStorageAccess()
    .then(function () {
      console.log("Storage access granted");
      setNeedStorageAccess(false, false);
    })
    .catch(function () {
      // User must have previously interacted with this domain loaded in a top frame.
      // Also you should have previously written a cookie when domain was loaded in the top frame.
      console.error("User cancelled or requirements were not met.");
      setNeedStorageAccess(true, true);
    });
}
