import { EMPLOYEE_BADGES, getBadgeData } from "./consts.tsx";
import { Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { format, addMonths } from "date-fns";
import { BadgeWrapper, IconWrapper, StyledContent } from "./styled.ts";
import { forwardRef } from "react";

interface BadgeProps {
  kind: keyof typeof EMPLOYEE_BADGES;
  badgeDate?: number;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(({ kind, badgeDate }, ref) => {
  const { translate } = useLocales();
  const badgeData = getBadgeData(kind);

  return badgeData ? (
    <BadgeWrapper ref={ref}>
      <IconWrapper>{badgeData?.icon}</IconWrapper>
      <StyledContent>
        <Typography color="text.primary" fontSize="14" fontWeight="600">
          {translate(badgeData.title)}
        </Typography>
        <Typography color="text.secondary" fontSize="14" fontWeight="400" lineHeight="normal">
          {translate(badgeData.subtitle, {
            date: format(addMonths(new Date((badgeDate ?? 0) * 1000), -1), "MMMM yyyy"),
          })}
        </Typography>
      </StyledContent>
    </BadgeWrapper>
  ) : null;
});
