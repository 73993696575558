import { Box, SxProps } from "@mui/material";

type LevelProgressBarProps = {
  progress: number;
  backgroundSx?: SxProps;
};

export function LevelProgressBar({ progress, backgroundSx }: LevelProgressBarProps) {
  return (
    <Box
      aria-label={`${progress}%`}
      sx={{
        width: "100%",
        height: 8,
        backgroundColor: "#231E60",
        borderRadius: 100,
        overflow: "hidden",
        ...backgroundSx,
      }}
    >
      <Box
        sx={{
          width: `${progress}%`,
          height: "100%",
          background: "linear-gradient(90deg, #0029FF -2.33%, #8000FF 132.65%)",
        }}
      ></Box>
    </Box>
  );
}
