import { Button, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export function NotFoundScreen() {
  const history = useHistory();
  return (
    <Grid container justifyContent="center" alignItems="center" flexDirection="column">
      <Typography>Nothing much here </Typography>
      <Button onClick={() => history.push("/")}>Go back</Button>
    </Grid>
  );
}
