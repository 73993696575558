import { Box, Button, Typography } from "@mui/material";
import { useLocales } from "../../hooks";

import { styled } from "@mui/material/styles";
import { Trans } from "react-i18next";
import { routes } from "../../settings/routes.ts";
import { useHistory } from "react-router-dom";

const StyledContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 24,
});

export function ThankYouScreen() {
  const { translate } = useLocales();
  const history = useHistory();

  const handleBack = () => {
    history.push(routes.assessment.index);
  };

  return (
    <StyledContent>
      <Typography fontSize="20px" fontWeight="600" color="text.primary">
        {translate("assessment.thankYou.title")}
      </Typography>
      <Typography fontSize="12px" fontWeight="400" color="text.secondary" textAlign="center">
        <Trans
          i18nKey="assessment.thankYou.description"
          components={{
            1: <br />,
          }}
        />
      </Typography>
      <Button variant="contained" sx={{ p: "8px 32px" }} onClick={handleBack}>
        {translate("assessment.thankYou.action")}
      </Button>
    </StyledContent>
  );
}
