import { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";

type DynamicHeightContainerProps = {
  children: (elementRefs: MutableRefObject<(HTMLDivElement | null)[]>) => ReactNode;
  count: number;
};

export function ScrollableHeightContainer({ children, count }: DynamicHeightContainerProps) {
  const [containerHeight, setContainerHeight] = useState("0px");
  const elementRefs = useRef<(HTMLDivElement | null)[]>([]);
  const gapPx = "16px";
  const updateHeight = useCallback(() => {
    const firstCountNumber = elementRefs.current
      .filter(Boolean)
      .slice(0, count)
      .reduce((acc, ref) => acc + (ref?.offsetHeight || 0), 0);
    setContainerHeight(`calc(${firstCountNumber}px + (${gapPx} * ${count - 1}))`);
  }, [count]);

  useEffect(() => {
    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [updateHeight]);

  return (
    <Stack
      sx={{
        gap: gapPx,
        marginTop: "12px",
        height: containerHeight,
        overflowY: "auto",
      }}
    >
      {children(elementRefs)}
    </Stack>
  );
}
