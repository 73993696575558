import { Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { useState } from "react";
import AccurateIcon from "../../assets/feedback/accurate.svg?react";
import NeutralIcon from "../../assets/feedback/neutral.svg?react";
import PoorIcon from "../../assets/feedback/poor.svg?react";
import { RadioButtonsGroup, RadioInput, RadioLabel } from "./styled.ts";

export function FeedbackRadioGroup() {
  const { translate } = useLocales();
  const [selected, setSelected] = useState(translate("assessment.provideFeedback.radio.accurate"));

  const labels = [
    { value: translate("assessment.provideFeedback.radio.accurate"), icon: <AccurateIcon /> },
    { value: translate("assessment.provideFeedback.radio.neutral"), icon: <NeutralIcon /> },
    { value: translate("assessment.provideFeedback.radio.poor"), icon: <PoorIcon /> },
  ];

  return (
    <RadioButtonsGroup>
      {labels.map(({ value, icon }) => (
        <RadioLabel key={value} selected={selected === value}>
          <RadioInput
            type="radio"
            name="radioFeedback"
            value={value}
            checked={selected === value}
            onChange={() => setSelected(value)}
          />
          {icon}
          <Typography fontSize="12px" fontWeight="400" color="text.secondary">
            {value}
          </Typography>
        </RadioLabel>
      ))}
    </RadioButtonsGroup>
  );
}
