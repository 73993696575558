export const routes = {
  root: "/",
  assessment: {
    index: "/assessment",
    thankYou: {
      index: "/thank-you",
    },
    provideFeedback: {
      index: "/provide-feedback",
    },
  },
  incident: {
    index: "/incident",
  },
  result: {
    index: "/result",
    badge: {
      index: "/badge",
    },
  },
};
