import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const BadgesList = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  padding: 12,
  borderRadius: 8,
  border: "1px solid #EAECF5",
});

export const IconWrapper = styled(Box)({
  width: 50,
  height: 50,
  flexShrink: 0,
});

export const BadgeWrapper = styled(Box)({
  display: "flex",
  gap: "12px",
  alignItems: "center",
});

export const StyledContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
export const BadgeInformationWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 12,
});

export const LevelProgressBarWrapper = styled(Box)({
  position: "relative",
  width: "100%",
  height: "20px",
  marginBottom: 8,
});

export const StyledTypography = styled(Typography)({
  position: "absolute",
  fontSize: 14,
  fontWeight: 400,
  color: "#fff",
  lineHeight: "20px",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
});
