import { styled } from "@mui/material/styles";
import { Box, TextField, Typography } from "@mui/material";

export const StyledWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 24,
});

export const StyledBlock = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 12,
});

export const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: 12,
});

export const TextArea = styled(TextField)({
  width: "100%",
  borderColor: "#D4D4D4",
  ".MuiInputBase-input": { fontSize: 12 },
  fontWeight: 400,
});

export const RadioLabel = styled("label")<{ selected: boolean }>(({ selected }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  padding: 8,
  cursor: "pointer",
  border: `1px solid ${selected ? "#0057ff" : "#EAECF5"}`,
  backgroundColor: selected ? "#DAEDFF" : "#FFF",
  transition: "all 0.2s ease-in-out",
  width: "100%",

  ["&:hover"]: {
    backgroundColor: "#daedff",
  },
}));

export const RadioInput = styled("input")({
  display: "none",
});

export const RadioButtonsGroup = styled(Box)({
  display: "flex",
  gap: 12,
  width: "100%",
});

export const TypographySecondary = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "400",
  color: theme.palette.text.secondary,
  textAlign: "center",
}));
