import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { ThemeProvider } from "@mui/material/styles";
import { ErrorBoundary } from "@sentry/react";
import { worker } from "./mocks/browser.ts";

import { theme } from "./styles";
import "./index.css";

import App from "./App";
import { queryClient } from "./api";
import { QueryClientProvider } from "react-query";

import { ErrorView } from "./components/ErrorView";
import { GeneralLayout } from "./components/GeneralLayout";
import { getCurrentCachedLocale } from "./locales";
import { initializePublicClient } from "./api/naaAuth.ts";

const replaceState = window.history.replaceState;
const pushState = window.history.pushState;
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
let isOfficeInitialized = false;

if (process.env.NODE_ENV === "development" && import.meta.env.VITE_IS_MOCKING_ENABLED === "true") {
  worker.start();
}

Sentry.init({
  dsn: "https://10ca7c2638074d3abc6ccf87ec6d431c@o1370541.ingest.us.sentry.io/4505080565596160",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Office.onReady(async () => {
  await initializePublicClient();
  const i18n = await import("./locales/i18n");

  await i18n.default.changeLanguage(getCurrentCachedLocale());

  window.history.replaceState = replaceState;
  window.history.pushState = pushState;
  isOfficeInitialized = true;
  root.render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <ErrorBoundary
          fallback={() => {
            return (
              <GeneralLayout isOfficeInitialized={isOfficeInitialized}>
                <ErrorView description="Ooops" />
              </GeneralLayout>
            );
          }}
        >
          <QueryClientProvider client={queryClient}>
            <App isOfficeInitialized={isOfficeInitialized} />
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StrictMode>,
  );
});
