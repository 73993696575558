import CyberNoviceIcon from "../../assets/level/CyberNovice.svg?react";
import SecurityLearnerIcon from "../../assets/level/SecurityLearner.svg?react";
import AwarenessApprenticeIcon from "../../assets/level/AwarenessApprentice.svg?react";
import CyberDefenderIcon from "../../assets/level/CyberDefender.svg?react";
import PhishingHunterIcon from "../../assets/level/PhishingHunter.svg?react";
import CyberExpertIcon from "../../assets/level/CyberExpert.svg?react";
import AironWorksGuardianIcon from "../../assets/level/AironWorksGuardian.svg?react";

export const EmployeeLevelData = {
  NOVICE: {
    title: "Cyber Novice",
    icon: <CyberNoviceIcon style={{ width: "100%", height: "100%" }} />,
    minScore: 0,
    maxScore: 49,
  },
  LEARNER: {
    title: "Security Learner",
    icon: <SecurityLearnerIcon style={{ width: "100%", height: "100%" }} />,
    minScore: 50,
    maxScore: 149,
  },
  APPRENTICE: {
    title: "Awareness Apprentice",
    icon: <AwarenessApprenticeIcon style={{ width: "100%", height: "100%" }} />,
    minScore: 150,
    maxScore: 299,
  },
  DEFENDER: {
    title: "Cyber Defender",
    icon: <CyberDefenderIcon style={{ width: "100%", height: "100%" }} />,
    minScore: 300,
    maxScore: 499,
  },
  HUNTER: {
    title: "Phishing Hunter",
    icon: <PhishingHunterIcon style={{ width: "100%", height: "100%" }} />,
    minScore: 500,
    maxScore: 699,
  },
  EXPERT: {
    title: "Cyber Expert",
    icon: <CyberExpertIcon style={{ width: "100%", height: "100%" }} />,
    minScore: 700,
    maxScore: 999,
  },
  GUARDIAN: {
    title: "AironWorks Guardian",
    icon: <AironWorksGuardianIcon style={{ width: "100%", height: "100%" }} />,
    minScore: 1000,
    maxScore: Infinity,
  },
};

export type EmployeeLevelKind = keyof typeof EmployeeLevelData;
