import {
  IPublicClientApplication,
  createNestablePublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { requiredScopes } from "./consts";

const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_CLIENT_ID as string,
    authority: "https://login.microsoftonline.com/9d545d03-ca63-42d4-9de5-f4bcafca0505",
    supportsNestedAppAuth: true,
  },
};

let pca: IPublicClientApplication;

export function initializePublicClient() {
  if (Office.context.requirements.isSetSupported("NestedAppAuth", "1.1")) {
    console.log("Starting initializePublicClient");
    return createNestablePublicClientApplication(msalConfig).then((result) => {
      console.log("Client app created");
      pca = result;
      return pca;
    });
  }
}

export function acquireToken(
  setToken: (token: string, access?: string) => void,
  setFailed: (failed: boolean) => void,
) {
  const accessTokenRequest = {
    account: pca.getActiveAccount() ?? undefined,
    scopes: requiredScopes,
  };
  pca
    .acquireTokenSilent(accessTokenRequest)
    .then((response) => {
      const idToken = response.idToken;
      const accessToken = response.accessToken;
      setToken(idToken, "MSN " + accessToken);
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        pca
          .acquireTokenPopup(accessTokenRequest)
          .then((response) => {
            // Acquire token interactive success
            const accessToken = response.accessToken;
            // Call your API with token
            setToken(response.idToken, "MSN " + accessToken);
          })
          .catch((error) => {
            console.log(error);
            setFailed(true);
          });
      } else {
        setFailed(true);
      }
      console.log(error);
    });
}
