import { Box, Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { LevelProgressBar } from "../LevelSection/LevelProgressBar.tsx";

import { LevelLogo } from "../LevelSection/LevelLogo.tsx";
import { EmployeeLevelData } from "../LevelSection/consts.tsx";
import { LevelProgressBarWrapper, StyledTypography } from "./styled.ts";

type LevelInformationProps = {
  progress: number;
};

export function LevelInformation({ progress }: LevelInformationProps) {
  const { translate } = useLocales();
  const levelKind = "LEARNER" as const;
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <LevelLogo levelKind={levelKind} sx={{ width: "120px", height: "120px" }} />
      <Typography
        textAlign="center"
        fontSize="16px"
        fontWeight="600"
        color="text.primary"
        mb="20px"
      >
        {EmployeeLevelData[levelKind]?.title}
      </Typography>
      <LevelProgressBarWrapper>
        <StyledTypography>750</StyledTypography>
        <LevelProgressBar
          progress={progress}
          backgroundSx={{
            height: "inherit",
          }}
        />
      </LevelProgressBarWrapper>
      <Typography textAlign="center" fontSize="14px" fontWeight="400" color="text.primary">
        {translate("assessment.badges.nextLevel", {
          points: 999,
        })}
      </Typography>
    </Box>
  );
}
