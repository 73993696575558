import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AssessmentDetails as IAssessmentDetails } from "../../api";

export interface AssessmentDetailsProps {
  data: IAssessmentDetails[];
}

export function AssessmentDetails({ data }: AssessmentDetailsProps) {
  return (
    <>
      {data.map((item, index) => {
        return (
          <Accordion
            elevation={0}
            key={index}
            sx={{
              backgroundColor: "transparent",
              borderBottom: index === data.length - 1 ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
              "&.Mui-expanded": {
                "&:before": {
                  opacity: 1,
                },
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h2" fontWeight="500" color="text.primary">
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="text.secondary">{item.description}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
