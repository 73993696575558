import { useQuery } from "react-query";
import { apiHandlers, getMessageId } from "../api";
import { getAdaptedCacheLocale } from "../locales";
import { useLoginContext } from "../api/loginContext";
import { useUserInfoQuery } from "./useUserInfoQuery";

const MAX_RETRY_TIME_SEC = 50;
const BASE_DELAY_IN_MS = 100;
const DELAY_GROWTH = 1.2;
const MAX_DELAY_IN_MS = 2000;

export function useAssessmentQuery() {
  const { accessToken } = useLoginContext();
  const { data } = useUserInfoQuery();
  return useQuery(
    ["assessment"],
    async () => {
      if (data?.soc_email === undefined) {
        throw new Error("No email address found");
      }

      const messageId = getMessageId();
      console.log("mail id for assessment is", messageId);

      // expects a promise, so we should be able to use async
      let result = await apiHandlers.assessment.check(
        {
          language: getAdaptedCacheLocale(),
          message_id: messageId,
          token: accessToken ?? undefined,
        },
        { headers: { Authorization: `${accessToken}` } },
      );
      const requestId = result.data.id;
      let currentDelay = BASE_DELAY_IN_MS;
      let totalWaitTime = 0;
      while (result.data.status !== "DONE" && result.data.status !== "ERROR") {
        await new Promise((resolve) => setTimeout(resolve, currentDelay));
        totalWaitTime += currentDelay / 1000;
        if (totalWaitTime > MAX_RETRY_TIME_SEC) {
          throw new Error("Assessment took too long to complete");
        }
        currentDelay = Math.min(MAX_DELAY_IN_MS, currentDelay * DELAY_GROWTH);
        result = await apiHandlers.assessment.check(
          {
            id: requestId,
          },
          { headers: { Authorization: `${accessToken}` } },
        );
      }
      if (result.data.status === "ERROR") {
        throw new Error("Assessment failed");
      }
      return result.data.assessment_result;
    },
    { enabled: !!accessToken },
  );
}
