import { ReactNode } from "react";
import { Box } from "@mui/material";
import { BadgeInformation } from "./BadgeInformation.tsx";
import { LevelInformation } from "./LevelInformation.tsx";

interface WithBadgeInformationProps {
  children: ReactNode;
  isShowBadges?: boolean;
}

export function WithBadgeInformation({
  children,
  isShowBadges = false,
}: WithBadgeInformationProps) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {children}
      {isShowBadges ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <LevelInformation progress={100} />
          <BadgeInformation />
        </Box>
      ) : null}
    </Box>
  );
}
